(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAuth.service:SessionService
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .service('SessionService', SessionService);

  function SessionService(moment, $mdDialog, $interval, Auth, Auxiliary, $state, $document, $translate, appConfig) {
    var self = this
      , sessionTimer = null
      , sessionTimeout = null
      , SESSION_TIMEOUT = 30
      , SESSION_TIMEOUT_STORAGE_KEY = 'sessionTimeout'
      , isDevelopmentMode = !appConfig.cors && appConfig.debug;

    this.setSessionTimeout = function setSessionTimeout(sessionTimeStart) {
      var start = sessionTimeStart ? moment(sessionTimeStart) : moment();
      var end = start.add(SESSION_TIMEOUT, 'minutes');
      var diff = end.diff(start);

      // Remove development mode check to enable session timeout
      if (isDevelopmentMode) {
        return;
      }

      localStorage.setItem(SESSION_TIMEOUT_STORAGE_KEY, end);
      sessionTimer = moment.duration(diff);
      sessionTimeout = $interval(self.updateSessionTimeout, 1000);
    };

    this.updateSessionTimeout = function updateSessionTimeout() {
      var start = moment();
      var end = localStorage.getItem(SESSION_TIMEOUT_STORAGE_KEY);
      var diff = moment(end).diff(start);

      sessionTimer = moment.duration(diff);

      var minutes = moment.duration(sessionTimer).minutes();
      var seconds = moment.duration(sessionTimer).seconds();

      if (minutes === 0 && seconds === 59) {
        $mdDialog.show(
          $mdDialog
            .confirm({focusOnOpen: false})
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('auth.sessionTimeout.content'))
            .ok($translate.instant('auth.sessionTimeout.ok'))
            .cancel($translate.instant('auth.sessionTimeout.cancel'))
        )
        .then(function () {
          Auxiliary.getHeartbeat().$promise
            .then(function (response) {
              return self.resetSessionTimeout(response.session_time);
            });
        });
      }

      if (minutes <= 0 && seconds <= 0) {
        $mdDialog.cancel();
        self.clearSessionTimeout();
        Auth.logout();
        $state.go('login');
      }
    };

    this.clearSessionTimeout = function clearSessionTimeout() {
      sessionTimer = null;
      $interval.cancel(sessionTimeout);
      sessionTimeout = null;
      localStorage.removeItem(SESSION_TIMEOUT_STORAGE_KEY);
    };

    this.resetSessionTimeout = function resetSessionTimeout(sessionTimeStart) {
      this.clearSessionTimeout();
      this.setSessionTimeout(sessionTimeStart);
    };

    this.getSessionTimer = function getSessionTimer() {
      return sessionTimer;
    };
  }
}());
